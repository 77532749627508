.App {
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  justify-content: center;
  min-width: min-content;
}

.App-header {
  background-color: #282c34;
  min-width: 36rem;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
